<template>
  <v-container v-if="!escuelaTienePermiso">
    <v-skeleton-loader type="list-item-two-line@5"></v-skeleton-loader>
  </v-container>
  <v-container v-else>
    <tabsCursosComponent v-if="app == 'campus'" />
    <listadoPlantillasComponent v-else />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import tabsCursosComponent from "./cursosCampus/tabsCursos.vue";
import listadoPlantillasComponent from "./cursoPlantilla/listadoPlantillasAreas.vue";
import { permisoMenuEscuela } from "../../../helpers/utilerias";

export default {
  name: "listadoCursosView",
  components: {
    tabsCursosComponent,
    listadoPlantillasComponent,
  },
  computed: {
    ...mapGetters(["app", "menu", "sessionToken"]),
  },
  watch: {
    sessionToken() {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      this.escuelaTienePermiso = true;
    },
  },
  mounted() {
    if (this.sessionToken) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      if (this.app === "campus") {
        this.$store.dispatch("setSesionCursoPadre", false);
        this.$store.dispatch("setTokenSesionHijo", null);
        localStorage.removeItem("agnus-padre-token");
      }
      this.escuelaTienePermiso = true;
    }
  },
  data() {
    return {
      escuelaTienePermiso: false,
    };
  },
};
</script>
