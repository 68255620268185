import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.mostrar),callback:function ($$v) {_vm.mostrar=$$v},expression:"mostrar"}},[_c(VCard,[_c(VToolbar,{attrs:{"color":"primary","dense":"","flat":"","dark":""}},[_c('strong',[_vm._v(" Mover curso de área")])]),_c(VContainer,[_c(VSelect,{attrs:{"dense":"","outlined":"","label":"Área","items":_vm.areas,"item-value":"_id","item-text":"nombre","disabled":_vm.loading,"no-data-text":"No hay áreas disponibles"},model:{value:(_vm.idArea),callback:function ($$v) {_vm.idArea=$$v},expression:"idArea"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","small":"","outlined":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancelar')}}},[_vm._v("Cancelar")]),_c(VBtn,{attrs:{"color":"primary","small":"","loading":_vm.loading},on:{"click":function($event){return _vm.cambiarArea()}}},[_vm._v("Mover")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }