<template>
  <v-dialog v-model="mostrar" width="370" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark
        ><strong>Importar curso</strong></v-toolbar
      >
      <v-container>
        <v-text-field
        class="separador"
          dense
          outlined
          v-model="codigo"
          label="Código"
          :disabled="loading"
          :error-messages="codigoErrors"
          @input="$v.codigo.$touch()"
          @blur="$v.codigo.$touch()"
        ></v-text-field>

        <v-select
          dense
          outlined
          messages="La plantilla se copiará en el área seleccionada"
          :items="areaPlantillas"
          v-model="areaSel"
          item-value="_id"
          item-text="nombre"
          label="Área de plantilla"
          :error="areaSel ? false : true"
          :disabled="loading"
        ></v-select>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          :class="{ 'disable-btn': codigoErrors.length > 0 || !codigo }"
          color="primary"
          small
          @click="importarPlantilla()"
        >
          Importar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { importarPlantillaService } from './cursoPlantilla.service';

export default {
  components: {
  },
  props: {
    mostrar: Boolean,
    areas: Array,
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["httpURL", "sessionToken"]),
    codigoErrors() {
      const errors = [];
      if (!this.$v.codigo.$dirty) return errors;
      !this.$v.codigo.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    codigo: { required },
  },
  data: () => ({
    loading: false,
    codigo: null,
    areaPlantillas: [],
    areaSel: null,
  }),
  created(){
    if(this.areas.length > 0) {
      this.areaPlantillas = this.areas;
      this.areaSel = this.areaPlantillas[0];
    }
  },
  methods: {
    async importarPlantilla() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          let data = { 
            codigo: this.codigo,
            area: this.areaSel,
          }
          const serverResponse = await importarPlantillaService(data);
          this.loading = false;
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            this.$emit("plantillaImportada", serverResponse.cursoImportado);
          }
        } catch (error) {
          this.loading = false;
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
<style scoped>
.separador{
  margin-bottom: 30px;
}
</style>