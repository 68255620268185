<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark
        ><strong> Mover curso de área</strong></v-toolbar
      >
      <v-container>
        <v-select
          dense
          outlined
          v-model="idArea"
          label="Área"
          :items="areas"
          item-value="_id"
          item-text="nombre"
          :disabled="loading"
          no-data-text="No hay áreas disponibles"
        ></v-select>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          small
          outlined
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn color="primary" small :loading="loading" @click="cambiarArea()"
          >Mover</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { cambioAreaService } from "./cursoPlantilla.service";

export default {
  name: "moverPlantillaArea",
  props: ["mostrar", "areas", "idPlantilla"],
  data: () => ({
    loading: false,
    idArea: null,
  }),
  created() {
    if (this.areas.length > 0) this.idArea = this.areas[0]._id;
  },
  methods: {
    async cambiarArea() {
      try {
        this.loading = true;
        let data = { idPlantilla: this.idPlantilla, idArea: this.idArea };
        const serverResponse = await cambioAreaService(data);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
          this.loading = false;
        } else {
          this.loading = false;
          data = { idArea: this.idArea, idPlantilla: this.idPlantilla };
          this.$emit("plantillaMovida", data);
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
  },
};
</script>
