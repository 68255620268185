<template>
  <v-card flat>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        dense
        hide-details
        :disabled="loading"
      ></v-text-field>
    </v-card-title>

    <v-card-text
      v-if="!loading && cursos.length == 0"
      style="text-align: center"
    >
      <p>No se han generado cursos.</p>
    </v-card-text>

    <listadoCursosLoadingComponent v-if="loading" />

    <v-card outlined>
      <v-list v-if="cursos.length > 0">
        <template v-for="(curso, index) in cursos">
          <cursoCampusItemComponent
            :key="`cursoItem-${curso._id}`"
            :curso="curso"
            @archivarCurso="archivarCurso"
          />
          <v-divider
            v-if="index < cursos.length - 1"
            :key="`cursoItemDivider-${curso._id}`"
            class="mx-3"
          ></v-divider>
        </template>
      </v-list>
    </v-card>

    <v-dialog v-model="loadingClone" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ loadingMsg }}
          <v-progress-linear indeterminate color="white" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import cursoCampusItemComponent from "./cursoCampusItem.vue";
import listadoCursosLoadingComponent from "../listadoCursosLoading.vue";
import { obtenerCursosUsuarioService, modificarArchivadoCursoService } from "./listadoCursosCampus.service";
import _ from 'underscore';

export default {
  name: "listadoCursosArchivados",
  components: {
    cursoCampusItemComponent,
    listadoCursosLoadingComponent,
  },
  computed: {
    ...mapGetters(["sessionToken", "tipoSesion", "userId"]),
    esProfesor() {
      const index = this.tipoSesion.findIndex((x) => x == "profesor");
      return index >= 0;
    },
    esAlumno() {
      const index = this.tipoSesion.findIndex((x) => x == "alumno");
      return index >= 0;
    },
  },
  watch: {
    sessionToken() {
      this.cargarCursos();
    },
  },
  data() {
    return {
      loading: false,
      cursos: [],
      limiteCursos: 0,
      limiteAlcanzado: false,
      agregarCursoDialog: false,
      inscribirCurso: false,
      loadingClone: false,
      loadingMsg: "",
    };
  },
  mounted() {
    if (this.sessionToken) this.cargarCursos();
  },
  methods: {
    async cargarCursos() {
      try {
        this.loading = true;
        const data = { tab: "archivados" };
        const serverResponse = await obtenerCursosUsuarioService(data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.cursos = serverResponse.cursosUsuario;
          this.limiteCursos = serverResponse.limiteCursos || 0;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async archivarCurso(cursoData){
      try {
        const idCurso = cursoData.idCurso;
        const archivado = cursoData.archivado;
        this.loadingClone = true;
        this.loadingMsg = "archivando curso";
        const serverResponse = await modificarArchivadoCursoService(idCurso, {archivado: !archivado});
        this.loadingClone = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const index = this.cursos.findIndex((x) => x._id.toString() == idCurso.toString());
          this.cursos.splice(index, 1);
        }
      } catch (error) {
        this.loadingClone = false;
        this.$appErrorMessage();
      }
    }
  },
};
</script>