<template>
  <v-dialog v-model="mostrar" :max-width="300" persistent>
    <v-card>
      <v-toolbar color="primary" dark flat dense class="text-bold">
        <v-toolbar-title>Inscribirme a curso</v-toolbar-title>
      </v-toolbar>
      <v-container>
        <v-text-field
          v-model="claveInscripcion"
          label="Clave de inscripción"
          outlined
          dense
          :disabled="loading"
          :error-messages="claveErrors"
          @input="validarClave()"
          @blur="validarClave()"
        >
        </v-text-field>
        <v-text-field
          v-if="requiereCodigo"
          v-model="codigoEmpresa"
          label="Código empresa"
          outlined
          dense
          :disabled="loading"
          :error-messages="codigoErrors"
          @input="validarCodigo()"
          @blur="validarCodigo()"
        >
        </v-text-field>
      </v-container>
      <v-divider class="mx-3"></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          outlined
          small
          :disabled="loading"
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          color="primary"
          dark
          small
          :loading="loading"
          :class="{ 'disable-btn': claveErrors.length > 0 }"
          @click="registrarClave()"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { registrarClaveService } from "./listadoCursosCampus.service";
import { validationMixin } from "vuelidate";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "inscribirCurso",
  props: {
    mostrar: Boolean,
    idCurso: String,
    permisoEscritura: { type: Boolean, default: false },
  },
  mixins: [validationMixin],
  validations: {
    claveInscripcion: { required, minLength: minLength(5) },
    codigoEmpresa: { required, minLength: minLength(5) },
  },
  computed: {
    ...mapGetters(["tipoSesion"]),
  },
  data() {
    return {
      loading: false,
      claveInscripcion: null,
      claveErrors: [],
      codigoEmpresa: null,
      codigoErrors: [],
      requiereCodigo: false,
    };
  },
  methods: {
    validarClave() {
      this.claveErrors = [];
      this.$v.claveInscripcion.$touch();
      !this.$v.claveInscripcion.required &&
        this.claveErrors.push("Campo requerido.");
      !this.$v.claveInscripcion.minLength &&
        this.claveErrors.push("Mínimo 5 caracteres.");
    },
    validarCodigo() {
      this.codigoErrors = [];
      this.$v.codigoEmpresa.$touch();
      !this.$v.codigoEmpresa.required &&
        this.codigoErrors.push("Campo requerido.");
      !this.$v.codigoEmpresa.minLength &&
        this.codigoErrors.push("Mínimo 5 caracteres.");
    },
    async registrarClave() {
      try {
        this.loading = true;
        const data = { clave: this.claveInscripcion };
        if (this.codigoEmpresa != null) data.codigo = this.codigoEmpresa;
        const serverResponse = await registrarClaveService(data);
        this.loading = false;
        if (!serverResponse.ok) {
          if (serverResponse.claveError)
            this.claveErrors.push(serverResponse.mensaje);
          else this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          if(serverResponse.requiereCodigo) {
            this.requiereCodigo = serverResponse.requiereCodigo;
            this.codigoErrors.push(serverResponse.mensajeSecundario);
          } else {
            const emitData = { curso: serverResponse.curso };
            this.$emit("cursoRegistrado", emitData);
            this.$emit('cancelar');
          }
        }
      } catch (error) {
        this.loading = false;
        this.$emit('cancelar');
        this.$appErrorMessage();
      }
    },
  },
};
</script>
