import { postServerRequest } from '../../../../services/serverRequest.service';
import { store } from '@/store/store';
const httpURL = store.getters.httpURL;

export async function cargarCiclosService(modeloEducativo, queryOptions) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/oferta/ciclos/${modeloEducativo}`;
    const config = { headers: { token: sessionToken } }
    const data = { busqueda: queryOptions.busqueda };
    return await postServerRequest(url, data, config);
}

export async function cargarClasesAsignadasService(bodyOptions) {
    const sessionToken = store.getters.sessionToken;
    const url = `${httpURL}/oferta/clases/asignadas`;
    const config = { headers: { token: sessionToken } }
    return await postServerRequest(url, bodyOptions, config);
}