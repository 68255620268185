<template>
  <v-container>
    <v-card>
      <v-toolbar color="secondary" dark flat class="text-h6">
        <v-spacer></v-spacer>
        Plantillas de cursos
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container>
        <v-row>
          <v-col v-if="app == 'escuela'" md="3" cols="12">
            <selectorAreasComponent
              v-model="modeloEducativo"
              :areas="modelosEducativos"
              :loading="loading"
              etiqueta="Modelo educativo"
              @change="cargarCarreras()"
            />
          </v-col>
          <v-col v-if="app == 'escuela'" md="6" cols="12">
            <v-select
              label="Carrera"
              :items="carreras"
              v-model="carreraSel"
              dense
              outlined
              hide-details
              :disabled="loading"
              item-value="_id"
              item-text="nombre"
              no-data-text="No hay áreas disponibles"
              @change="cargarAreas()"
            >
            </v-select>
          </v-col>
          <v-col md="3" cols="12">
            <selectorAreasComponent
              :areas="areas"
              v-model="areaSelect"
              :menu="role == 'admin' || role == 'master'"
              @change="cargarPlantillas()"
              @agregarArea="agregarArea = true"
              @modificarArea="modificarAreaAction()"
            />
          </v-col>
        </v-row>

        <v-row style="margin: 0px">
          <v-col md="12" style="padding: 0px">
            <v-list>
              <v-col cols="12" style="padding: 0px">
                <listadoPlantillasComponent
                  :cursos="plantillas"
                  :area="areaSelect"
                  :areas="areas"
                  :modeloEducativo="modeloEducativo"
                  @plantillaAgregada="plantillaAgregada"
                  @plantillaMovida="plantillaMovida"
                  @plantillaClonada="plantillaClonada"
                  @plantillaImportada="plantillaImportada"
                />
              </v-col>
            </v-list>
          </v-col>
        </v-row>
      </v-container>

      <agregarAreaComponent
        v-if="agregarArea"
        :mostrar="agregarArea"
        :carrera="carreraSel"
        @cancelar="agregarArea = false"
        @areaAgregada="areaAgregada"
      />

      <modificarAreaComponent
        v-if="modificarArea.mostrar"
        :mostrar="modificarArea.mostrar"
        :area="modificarArea.area"
        @cancelar="modificarArea.mostrar = false"
        @areaModificada="areaModificada"
      />
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import {
  obtenerAreasService,
  obtenerPlantillasService,
  obtenerCarrerasService,
} from "./cursoPlantilla.service";
import { cargarModelosEducativos } from "../../../modelosEducativos/modelosEducativos.service"

import selectorAreasComponent from "../../../widgets/selectorAreas.vue";
import agregarAreaComponent from "./agregarAreaPlantilla.vue";
import modificarAreaComponent from "./modificarAreaPlantilla.vue";
import listadoPlantillasComponent from "./listadoPlantillas.vue";

export default {
  name: "listadoPlantillasAreas",
  components: {
    selectorAreasComponent,
    agregarAreaComponent,
    modificarAreaComponent,
    listadoPlantillasComponent,
  },
  computed: {
    ...mapGetters(["app", "role", "sessionToken"]),
    puedeAgregarArea() {
      return this.role == "admin";
    },
    puedeModificarArea() {
      return this.role == "admin";
    },

  },
  watch: {
    sessionToken() {
      if (this.app == "escuela") {
        this.cargarModelos();
      }
      if (this.app == "empresa") {
        this.cargarAreas();
      }
    },
  },
  data() {
    return {
      loading: false,
      tab: null,
      cursos: [],
      areas: [],
      carreras: [],
      plantillas: [],
      modeloEducativo: '',
      modelosEducativos: [],
      agregarArea: false,
      modificarArea: {
        mostrar: false,
        area: {},
      },
      agregarCodigoCurso: false,
      areaSelect: null,
      carreraSel: null,
    };
  },
  mounted() {
    if (this.sessionToken) {
      if (this.app == "escuela") {
        this.cargarModelos();
      }
      if (this.app == "empresa") {
        this.cargarAreas();
      }
    }
  },
  methods: {
    async cargarModelos() {
      try {
        this.loading = true;
        const serverResponse = await cargarModelosEducativos();
        this.loading = false;
        this.modelosEducativos = serverResponse.modelosEducativos;
        if (this.modelosEducativos.length > 0){
          this.modeloEducativo = this.modelosEducativos[0]._id;
          this.cargarCarreras();
        }
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
    modificarAreaAction() {
      if(this.areaSelect != null){
        let area = this.areas.find((x) => x._id == this.areaSelect);
        this.modificarArea.area = area;
        this.modificarArea.mostrar = true;
      }else this.agregarArea = true;
    },
    async cargarCarreras() {
      try {
        const serverResponse = await obtenerCarrerasService(this.modeloEducativo);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.carreras = serverResponse.carreras;
          if (this.carreras.length > 0) {
            this.carreraSel = this.carreras[0]._id;
            await this.cargarAreas();
          }
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    async cargarAreas() {
      try {
        let data = null;
        if (this.app == "escuela") {
          data = { carrera: this.carreraSel };
        }
        const serverResponse = await obtenerAreasService(data);
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.areas = serverResponse.areas;
          if (this.areas.length > 0) {
            this.areaSelect = this.areas[0]._id;
            await this.cargarPlantillas();
          } else {
            this.areas = [];
            this.areaSelect = null;
            this.plantillas = [];
          }
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    async cargarPlantillas() {
      try {
        this.plantillas = [];
        if (this.areaSelect) {
          this.loading = true;
          const serverResponse = await obtenerPlantillasService(
            this.areaSelect
          );
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
          } else {
            this.plantillas = serverResponse.plantillas;
            this.loading = false;
          }
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    modificarAreaSeleccionada(areaSeleccionada){
      let area = this.areas.find((x) => x._id == areaSeleccionada);
      this.modificarArea.area = area;
      this.modificarArea.mostrar = true;
    },
    areaAgregada(data) {
      this.areas.push(data.area);
      this.areaSelect = data.area._id;
      this.agregarArea = false;
      // this.log.unshift(data.log);
      this.cargarPlantillas();
    },
    areaModificada(data) {
      let index = this.areas.findIndex((x) => x._id == this.areaSelect);
      this.areas[index].nombre = data.nombre;
      this.modificarArea.mostrar = false;
      // this.log.unshift(data.log);
    },
    plantillaAgregada(plantilla) {
      this.plantillas.push(plantilla.plantilla);
      // this.clonarCursoCodigo.mostrar = false;
      // this.log.unshift(data.log);
    },
    plantillaClonada(plantilla) {
      this.plantillas.push(plantilla);
    },
    plantillaImportada(plantilla){
      this.plantillas.push(plantilla);
    },
    plantillaMovida(data) {
      let indexPlantilla = this.plantillas.findIndex(
        (x) => x._id == data.idPlantilla
      );
      this.plantillas.splice(indexPlantilla, 1);
    },
    abrirPlantilla(plantilla) {
      if (!plantilla.vencida)
        this.$router.push(`/curso/${this.app}/${plantilla._id}`);
    },
  },
};
</script>

<style></style>
