import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,[_c(VSpacer),_c(VTextField,{attrs:{"append-icon":"mdi-magnify","label":"Buscar","outlined":"","dense":"","hide-details":"","disabled":_vm.loading}})],1),(!_vm.loading && _vm.cursos.length == 0)?_c(VCardText,{staticStyle:{"text-align":"center"}},[_c('p',[_vm._v("No se han generado cursos.")])]):_vm._e(),(_vm.loading)?_c('listadoCursosLoadingComponent'):_vm._e(),_c(VCard,{attrs:{"outlined":""}},[(_vm.cursos.length > 0)?_c(VList,[_vm._l((_vm.cursos),function(curso,index){return [_c('cursoCampusItemComponent',{key:("cursoItem-" + (curso._id)),attrs:{"curso":curso}}),(index < _vm.cursos.length - 1)?_c(VDivider,{key:("cursoItemDivider-" + (curso._id)),staticClass:"mx-3"}):_vm._e()]})],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }