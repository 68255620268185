<template>
  <v-card flat>
    <v-card-title>
      <v-menu offset-y v-if="esProfesor || esAlumno">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="primary"
            :disabled="loading"
            small
            fab
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-newspaper-plus</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="esProfesor" @click="agregarCurso()">
            <v-list-item-icon>
              <v-icon>mdi-book-open-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Crear curso en blanco</v-list-item-title>
              <v-list-item-subtitle>como profesor</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="esProfesor" @click="agregarCodigoCurso = true">
            <v-list-item-icon>
              <v-icon>mdi-barcode</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Crear curso mediante código</v-list-item-title>
              <v-list-item-subtitle>como profesor</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="esAlumno" @click="inscribirCurso = true">
            <v-list-item-icon>
              <v-icon>mdi-book-account-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Inscribirme a un curso</v-list-item-title>
              <v-list-item-subtitle>como alumno</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        dense
        hide-details
        :disabled="loading"
      ></v-text-field>
    </v-card-title>

    <v-card-text
      v-if="!loading && cursos.length == 0"
      style="text-align: center"
    >
      <p>No se han generado cursos.</p>
    </v-card-text>

    <listadoCursosLoadingComponent v-if="loading" />

    <v-card outlined>
      <v-list v-if="cursos.length > 0">
        <template v-for="(curso, index) in cursos">
          <cursoCampusItemComponent
            :key="`cursoItem-${curso._id}`"
            :curso="curso"
            @clonarCurso="clonarCurso"
            @cursoImportadoClonado="cursoImportadoClonado"
            @archivarCurso="archivarCurso"
          />
          <v-divider
            v-if="index < cursos.length - 1"
            :key="`cursoItemDivider-${curso._id}`"
            class="mx-3"
          ></v-divider>
        </template>
      </v-list>
    </v-card>

    <agregarCursoCampusComponent
      v-if="agregarCursoDialog"
      :mostrar="agregarCursoDialog"
      :limiteCursos="limiteCursos"
      @cancelar="agregarCursoDialog = false"
      @cursoAgregado="cursoAgregado"
    />

    <limiteCursoAlcanzadoComponent
      v-if="limiteAlcanzado"
      :mostrar="limiteAlcanzado"
      @cancelar="limiteAlcanzado = false"
    />

    <inscribirCursoComponent
      v-if="inscribirCurso"
      :mostrar="inscribirCurso"
      @cancelar="inscribirCurso = false"
      @cursoRegistrado="cursoRegistrado"
    />

    <v-dialog v-model="loadingClone" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ loadingMsg }}
          <v-progress-linear indeterminate color="white" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <importarCursoByCodigoComponent
      v-if="agregarCodigoCurso"
      :mostrar="agregarCodigoCurso"
      @cancelar="agregarCodigoCurso = false"
      @cursoImportado="cursoImportado"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import cursoCampusItemComponent from "./cursoCampusItem.vue";
import listadoCursosLoadingComponent from "../listadoCursosLoading.vue";
import agregarCursoCampusComponent from "./agregarCursoCampus.vue";
import limiteCursoAlcanzadoComponent from "./limiteCursoAlcanzado.vue";
import inscribirCursoComponent from "./inscribirCurso.vue";
import { obtenerCursosUsuarioService, clonarCursoService, modificarArchivadoCursoService } from "./listadoCursosCampus.service";
import importarCursoByCodigoComponent from "./importarCursoByCodigo.vue"
import _ from 'underscore';

export default {
  name: "listadoCursosCampus",
  components: {
    cursoCampusItemComponent,
    listadoCursosLoadingComponent,
    agregarCursoCampusComponent,
    limiteCursoAlcanzadoComponent,
    inscribirCursoComponent,
    importarCursoByCodigoComponent
  },
  computed: {
    ...mapGetters(["sessionToken", "tipoSesion", "userId"]),
    cursosPersonales() {
      let cursos = 0;
      this.cursos.forEach((curso) => {
        if (!curso.empresa && !curso.escuela) cursos++;
      });
      return cursos;
    },
    esProfesor() {
      const index = this.tipoSesion.findIndex((x) => x == "profesor");
      return index >= 0;
    },
    esAlumno() {
      const index = this.tipoSesion.findIndex((x) => x == "alumno");
      return index >= 0;
    },
  },
  watch: {
    sessionToken() {
      this.cargarCursos();
    },
  },
  data() {
    return {
      loading: false,
      cursos: [],
      limiteCursos: 0,
      limiteAlcanzado: false,
      agregarCursoDialog: false,
      inscribirCurso: false,
      loadingClone: false,
      loadingMsg: "",
      agregarCodigoCurso: false,
    };
  },
  mounted() {
    if (this.sessionToken) this.cargarCursos();
  },
  methods: {
    async cargarCursos() {
      try {
        this.loading = true;
        const data = { tab: "cursos" };
        const serverResponse = await obtenerCursosUsuarioService(data);
        this.loading = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.cursos = serverResponse.cursosUsuario
          this.limiteCursos = serverResponse.limiteCursos || 0;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    agregarCurso() {
      if (this.limiteCursos == -1 || this.cursosPersonales < this.limiteCursos)
        this.agregarCursoDialog = true;
      else this.limiteAlcanzado = true;
    },
    agregarCursoByCodigo() {
      
    },
    cursoAgregado(data) {
      this.agregarCursoDialog = false;
      this.cursos.push(data.curso);
    },
    cursoRegistrado(data) {
      this.inscribirCurso = false;
      this.cursos.push(data.curso);
    },
    async clonarCurso(idCurso){
      try {
        this.loadingClone = true;
        this.loadingMsg = "Clonando plantilla";
        const serverResponse = await clonarCursoService(idCurso);
        this.loadingClone = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          this.cursos.push(serverResponse.cursoClonado);
        }
      } catch (error) {
        this.loadingClone = false;
        this.$appErrorMessage();
      }
    },
    cursoImportado(curso){
      this.agregarCodigoCurso = false;
      this.cursos.push(curso)
    },
    cursoImportadoClonado(curso){
      this.cursos.push(curso)
    },
    async archivarCurso(cursoData){
      try {
        const idCurso = cursoData.idCurso;
        const archivado = cursoData.archivado;
        this.loadingClone = true;
        this.loadingMsg = "archivando curso";
        const serverResponse = await modificarArchivadoCursoService(idCurso, {archivado: !archivado});
        this.loadingClone = false;
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else {
          const index = this.cursos.findIndex((x) => x._id.toString() == idCurso.toString());
          this.cursos.splice(index, 1);
        }
      } catch (error) {
        this.loadingClone = false;
        this.$appErrorMessage();
      }
    }
  },
};
</script>
