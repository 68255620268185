<template>
  <div>
    <v-list-item>
      <v-list-item-avatar v-if="!curso.icon" size="40" color="secondary">
        <v-icon color="white">mdi-book-open-outline</v-icon>
      </v-list-item-avatar>

      <v-list-item-avatar v-else size="40">
        <v-img :src="`${imageURL}/${curso.icon}`" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title>
          <a
            href="javascript:void(0)"
            class="link"
            @click="abrirCurso(curso._id)"
            >{{ curso.nombre }}</a
          >
        </v-list-item-title>
        <v-list-item-subtitle>
          <genericoChipComponent :text="curso.modulos" icon="mdi-view-list" />
          <genericoChipComponent
            :text="curso.actividades"
            icon="mdi-format-list-text"
          />
          <genericoChipComponent
            :text="curso.colaboradores"
            icon="mdi-account"
          />
          <v-chip v-if="curso.vigencia" :color="color" label small outlined>
            <v-icon small :color="color" class="labelIcon">mdi-clock-outline</v-icon>
            <span class="labelText">{{ fechaValida }}</span>
          </v-chip>
          <span v-if="curso.vigencia"> {{ curso.empresa.nombreCorto }}</span>
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action v-if="curso.permisoEscritura">
        <v-menu offset-x left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="modificarNombre = true">
              <v-list-item-title>Modificar nombre</v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item @click="modificarIcono = true">
              <v-list-item-title>Modificar ícono</v-list-item-title>
              <v-list-item-icon>
                <v-icon small>mdi-image-edit</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="role == 'admin'"
              @click="validarTipoClonado(curso._id)"
            >
              <v-list-item-title>Clonar plantilla</v-list-item-title>
              <v-list-item-icon
                ><v-icon small>mdi-content-copy</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-list-item v-if="role == 'admin'" @click="moverPlantillaArea(curso._id)">
              <v-list-item-title>Mover de área</v-list-item-title>
              <v-list-item-icon
                ><v-icon small
                  >mdi-subdirectory-arrow-left</v-icon
                ></v-list-item-icon
              >
            </v-list-item>
            <v-list-item v-if="app == 'escuela'" @click="aplicarCurso = true">
              <v-list-item-title>Aplicar a mis cursos</v-list-item-title>
              <v-list-item-icon
                ><v-icon small
                  >mdi-briefcase-download-outline</v-icon
                ></v-list-item-icon
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item-action>
    </v-list-item>

    <agregarPlantillaComponent
      v-if="modificarNombre"
      :mostrar="modificarNombre"
      :nombreAnterior="curso.nombre"
      :curso="curso._id"
      :tipo="'plantilla'"
      @cancelar="modificarNombre = false"
      @cursoModificado="cursoModificado"
    />

    <modificarIconoComponent
      v-if="modificarIcono"
      :mostrar="modificarIcono"
      :idCurso="curso._id"
      :tipoCurso="'plantilla'"
      @cancelar="modificarIcono = false"
      @iconoModificado="iconoModificado"
    />

    <moverPlantillaAreaComponent
      v-if="moverPlantilla.mostrar"
      :mostrar="moverPlantilla.mostrar"
      :areas="moverPlantilla.areas"
      :idPlantilla="moverPlantilla.idPlantilla"
      @cancelar="moverPlantilla.mostrar = false"
      @plantillaMovida="plantillaMovida"
    />
    
    <clonarPlantillaImportada
      v-if="clonarImportada"
      :mostrar="clonarImportada"
      :idPlantilla="curso._id"
      :accion="accion"
      @plantillaImportadaClonada="plantillaImportadaClonada"
      @cursoReActivado="cursoReActivado"
      @cancelar="clonarImportada = false"
    />

    <aplicarPlantillaComponent
      v-if="aplicarCurso"
      :mostrar="aplicarCurso"
      :idCurso="curso._id"
      :nombre="curso.nombre"
      :modeloEducativo="modeloEducativo"
      @cancelar="aplicarCurso = false"
    />

    <v-dialog v-model="loadingClone" persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ loadingMsg }}
          <v-progress-linear indeterminate color="white" class="mb-0">
          </v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import genericoChipComponent from "../../../widgets/chips/genericoChip.vue";
import agregarPlantillaComponent from "./agregarPlantilla.vue";
import moverPlantillaAreaComponent from "./moverPlantillaArea.vue";
import modificarIconoComponent from "../modificarIconoCurso.vue";
import { clonarPlantillaService } from "./cursoPlantilla.service";
import clonarPlantillaImportada from "./clonarPlantillaImportada.vue";
import aplicarPlantillaComponent from "./aplicarPlantillaAMisCursos.vue";
import { DateTime } from "luxon";

export default {
  name: "cursoPlantillaItem",
  components: {
    genericoChipComponent,
    moverPlantillaAreaComponent,
    modificarIconoComponent,
    agregarPlantillaComponent,
    clonarPlantillaImportada,
    aplicarPlantillaComponent,
  },
  props: ["curso", "area", "areas", "modeloEducativo"],
  data() {
    return {
      modificarNombre: false,
      modificarIcono: false,
      clonarImportada: false,
      aplicarCurso: false, 
      loading: false,
      moverPlantilla: {
        mostrar: false,
        areas: [],
        idPlantilla: null,
      },
      clonarCursoCodigo: {
        mostrar: false,
        idCurso: null,
      },
      loadingClone: false,
      loadingMsg: "",
      fechaValida: null,
      color: 'green',
      accion: ''
    };
  },
  computed: {
    ...mapGetters(["role", "app", "imageURL"]),
  },
  watch: {
    "curso.vigencia"() {
      this.validarFechaCursoImportado();
    },
  },
  created(){
    this.validarFechaCursoImportado();
  },
  methods: {
    abrirCurso(idCurso) {
      if (this.curso.vigencia && DateTime.fromISO(this.curso.vigencia) < DateTime.now()) {
        this.accion = 'renovarCodigo'
        this.clonarImportada = true;
      }
      else this.$router.push(`/curso/plantilla/${idCurso}/#encuadre`);
    },
    iconoModificado(icono) {
      this.curso.icon = icono;
      this.modificarIcono = false;
    },
    moverPlantillaArea(idPlantilla) {
      this.moverPlantilla.idPlantilla = idPlantilla;
      this.moverPlantilla.areas = this.areas.filter((x) => x._id !== this.area);
      this.moverPlantilla.mostrar = true;
    },
    plantillaMovida(data) {
      this.moverPlantilla.mostrar = false;
      this.$emit("plantillaMovida", data);
    },
    validarTipoClonado(idCurso){
      if (this.curso.vigencia && this.curso.empresa) {
        this.accion = 'clonarImportada'
        this.clonarImportada = true;
      }
      else this.clonarPlantilla(idCurso)
    },
    async clonarPlantilla(idCurso) {
      try {
        this.loadingClone = true;
        this.loadingMsg = "Clonando plantilla";
        const serverResponse = await clonarPlantillaService(idCurso);
        this.loadingClone = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("plantillaClonada", serverResponse.cursoClonado);
        }
      } catch (error) {
        this.$appErrorMessage();
      }
    },
    plantillaImportadaClonada(curso){
      this.$emit("plantillaClonada", curso);
    },
    cursoReActivado(curso){
      this.curso.vigencia = curso.vigencia;
    },
    cursoModificado(nombreCurso) {
      this.modificarNombre = false;
      this.curso.nombre = nombreCurso;
    },
    validarFechaCursoImportado(){
      if(this.curso.vigencia && DateTime.fromISO(this.curso.vigencia) > DateTime.now()) {
        this.fechaValida = DateTime.fromISO(this.curso.vigencia).toFormat('dd-MM-yyyy');
        this.color = 'green';
      }
      if(this.curso.vigencia && DateTime.fromISO(this.curso.vigencia) < DateTime.now()) {
        this.fechaValida = 'Vencida';
        this.color = 'red';
      }
    }
  },
};
</script>

<style>
.icon {
  order: 0;
}

.header {
  order: 1;
}
</style>
