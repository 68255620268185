<template>
  <v-card>
    <v-tabs
      v-model="tab"
      centered
      icons-and-text
      background-color="secondary"
      dark
    >
      <v-tabs-slider color="warning"></v-tabs-slider>
      <v-tab href="#cursos" to="#cursos">
        Cursos
        <v-icon>mdi-book-open-outline</v-icon>
      </v-tab>
      <v-tab href="#compartidos" to="#compartidos">
        Compartidos
        <v-icon>mdi-account-switch</v-icon>
      </v-tab>
      <v-tab href="#compartidos" to="#archivo">
        Archivo
        <v-icon>mdi-archive</v-icon>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item value="cursos">
        <v-container>
          <v-row>
            <v-col cols="12">
              <listadoCursosCampusComponent />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="compartidos"> 
        <v-container>
          <v-row>
            <v-col cols="12">
              <listadoCursosCompartidosComponent />
            </v-col>
          </v-row>
        </v-container>
      </v-tab-item>
      <v-tab-item value="archivo"> 
        <listadoCursosArchivadosComponent />
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import listadoCursosCampusComponent from "./listadoCursosCampus.vue";
import listadoCursosCompartidosComponent from "./listadoCursosCompartidos.vue"
import listadoCursosArchivadosComponent from "./listadoCursosArchivados.vue"

export default {
  name: "tabsCursos",
  components: {
    listadoCursosCampusComponent,
    listadoCursosCompartidosComponent,
    listadoCursosArchivadosComponent
  },
  computed: {
    ...mapGetters(["app", "sessionToken"]),
  },

  data() {
    return {
      tab: null,
    };
  },
};
</script>
