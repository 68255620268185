<template>
  <v-card flat>
    <v-card-title>
      <div v-if="app == 'empresa' || app == 'escuela'">
        <v-menu v-if="puedeAgregarPlantilla">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              v-bind="attrs"
              v-on="on"
              :disabled="bloquearBoton"
              color="primary"
            >
              <v-icon small style="margin-right: 5px">mdi-text-box-plus</v-icon
              >&nbsp; Nueva plantilla
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item @click="nuevaPlantilla = true">
              <v-list-item-title>Plantilla en blanco</v-list-item-title>
              <v-list-item-icon>
                <v-icon small style="margin-right: 5px"
                  >mdi-file-outline</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
            <v-list-item
              v-if="app == 'escuela'"
              @click="agregarCodigoCurso = true"
            >
              <v-list-item-title>Ingresar código</v-list-item-title>
              <v-list-item-icon>
                <v-icon small style="margin-right: 5px"
                  >mdi-barcode-scan</v-icon
                >
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <v-spacer></v-spacer>
      <v-text-field
        append-icon="mdi-magnify"
        label="Buscar"
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-card-text v-if="cursos.length == 0" style="text-align: center">
      <p>No se han generado plantillas.</p>
    </v-card-text>

    <v-card outlined v-else>
      <v-list>
        <template v-for="(curso, index) in cursos">
          <cursoPlantillaItemComponent
            :key="`cursoItem-${index}`"
            :curso="curso"
            :area="area"
            :areas="areas"
            :modeloEducativo="modeloEducativo"
            @plantillaMovida="plantillaMovida"
            @plantillaAgregada="plantillaAgregada"
            @plantillaClonada="plantillaClonada"
          />
          <v-divider
            :key="`cursoItemDivider-${index}`"
            v-if="index < cursos.length - 1"
          ></v-divider>
        </template>
      </v-list>
    </v-card>

    <agregarPlantillaComponent
      v-if="nuevaPlantilla"
      :mostrar="nuevaPlantilla"
      :idArea="area"
      @cancelar="nuevaPlantilla = false"
      @plantillaAgregada="plantillaAgregada"
    />

    <importarPlantillaByCodigoComponent
      v-if="agregarCodigoCurso"
      :mostrar="agregarCodigoCurso"
      :areas="areas"
      @cancelar="agregarCodigoCurso = false"
      @plantillaImportada="plantillaImportada"
    />
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import cursoPlantillaItemComponent from "./cursoPlantillaItem.vue";
import agregarPlantillaComponent from "./agregarPlantilla.vue";
import importarPlantillaByCodigoComponent from "./importarPlantillaByCodigo.vue"

export default {
  name: "listadoPlantillas",
  props: {
    cursos: Array,
    area: String,
    areas: Array,
    modeloEducativo: String,
  },
  components: {
    cursoPlantillaItemComponent,
    agregarPlantillaComponent,
    importarPlantillaByCodigoComponent
  },
  computed: {
    ...mapGetters(["role", "app"]),
    puedeAgregarPlantilla() {
      return this.role == "admin" || this.role == "master";
    },
    bloquearBoton() {
      if (this.area) {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      loading: false,
      nuevaPlantilla: false,
      agregarCodigoCurso: false,
    };
  },
  created() {},
  methods: {
    plantillaAgregada(data) {
      this.nuevaPlantilla = false;
      this.$emit("plantillaAgregada", data);
    },
    plantillaMovida(data) {
      this.$emit("plantillaMovida", data);
    },
    plantillaClonada(data) {
      this.$emit("plantillaClonada", data);
    },
    plantillaImportada(data){
      this.agregarCodigoCurso = false;
      this.$emit("plantillaImportada", data);
    }
  },
};
</script>
