<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card>
      <v-toolbar color="danger" dense flat dark
        ><strong> Límite alcanzado</strong></v-toolbar
      >
      <v-card-text>
        Estimado usuario, se ha alcanzado el límite de cursos en su cuenta.
        <br /><br />
        Para poder generar más cursos, favor de comunicarse a soporte AGNUS para
        ofrecerle un plan a su medida.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="$emit('cancelar')"
          >Cerrar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "limiteCursoAlcanzado",
  props: {
      mostrar: Boolean
  },
  data: () => ({
    loading: false,
  }),
};
</script>
