<template>
  <v-dialog v-model="mostrar" width="500" persistent>
    <v-card :loading="loading">
      <v-toolbar color="primary" dense flat dark
        ><strong> Agregar área</strong></v-toolbar
      >
      <v-container>
        <v-text-field
          dense
          outlined
          v-model="nombre"
          label="Nombre"
          :disabled="loading"
          :error-messages="nombreErrors"
          @input="$v.nombre.$touch()"
          @blur="$v.nombre.$touch()"
        ></v-text-field>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          outlined
          color="primary"
          :disabled="loading"
          @click="$emit('cancelar')"
          >Cancelar</v-btn
        >
        <v-btn small color="primary" :loading="loading" @click="agregarArea()">
          Agregar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { crearAreaService } from "./cursoPlantilla.service";

export default {
  props:{
    mostrar: Boolean,
    carrera: String || null
  },
  mixins: [validationMixin],
  computed: {
    ...mapGetters(["app"]),
    nombreErrors() {
      const errors = [];
      if (!this.$v.nombre.$dirty) return errors;
      !this.$v.nombre.required && errors.push("Campo requerido.");
      return errors;
    },
  },
  validations: {
    nombre: { required },
  },
  data: () => ({
    loading: false,
    nombre: null,
  }),
  created() {},
  methods: {
    async agregarArea() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          let data = { nombre: this.nombre };
          if (this.app == "escuela") {
            data.carrera = this.carrera;
          }
          const serverResponse = await crearAreaService(data);
          if (!serverResponse.ok) {
            this.$systemErrorMessage(serverResponse.mensaje);
            this.loading = false;
          } else {
            this.loading = false;
            data = { area: serverResponse.nuevaArea };
            this.$emit("areaAgregada", data);
          }
        } catch (error) {
          this.$appErrorMessage();
        }
      }
    },
  },
};
</script>
