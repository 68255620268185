<template>
  <v-dialog v-model="mostrar" maxWidth="700" persistent>
    <v-card>
      <v-toolbar color="primary" dense flat dark
        ><strong>Aplicar plantilla</strong></v-toolbar
      >
      <v-container>
        <v-row>
          <v-col md="3" style="padding-bottom: 1px; padding-left: 30px;">
            <strong>{{ nombre }}</strong>
            <v-select
              label="Ciclo:"
              v-model="cicloSel"
              :items="ciclos"
              item-text="nombre"
              outlined
              dense
              return-object
              @change="cargarClases()"
              :disabled="loading"
            ></v-select>
          </v-col>

          <v-col cols="12" style="padding-top: 1px;">
            <v-list dense style="padding-top: 1px;">
              <v-subheader style="margin-left: 10px; margin-right: 20px;"
                >Seleccione los cursos a los que desea aplicar la
                plantilla</v-subheader
              >
              <v-list-item v-for="curso in clases" :key="curso._id">
                <v-list-item-action>
                  <v-checkbox
                    v-model="selected"
                    :value="curso._id"
                    :disabled="loading"
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title
                    >{{ curso.ciclo.nombre }} {{ curso.grado.numero
                    }}{{ curso.grupo.letra }}{{ curso.turno.nombre[0] }}
                    {{ curso.materia.nombre }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="curso.cursoCampus">{{curso.cursoCampus.nombre}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-alert
              outlined
              dense
              type="warning"
              border="left"
              style="margin-left: 20px; margin-right: 20px;"
            >
              Al aplicar esta plantilla se eliminará TODO el contenido que
              existe en los cursos seleccionados y será remplazado por un curso
              en blanco.
            </v-alert>
          </v-col>
        </v-row>
      </v-container>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          color="primary"
          outlined
          small
          @click="$emit('cancelar')"
        >
          Cancelar
        </v-btn>
        <v-btn
          :loading="loading"
          :class="{ 'disable-btn': selected.length == 0 }"
          color="primary"
          small
          @click="aplicarACursos()"
        >
          Aplicar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  cargarCiclosService,
  cargarClasesAsignadasService,
} from "./oferta.service";
import { aplicarPlantillaService } from "./cursoPlantilla.service";
import { mapGetters } from "vuex";
export default {
  props: ["mostrar", "idCurso", "nombre", "modeloEducativo"],
  computed: {
    ...mapGetters(["httpURL", "sessionToken", "colors"]),
  },
  data: () => ({
    loading: false,
    ciclos: [],
    cicloSel: { cursos: [] },
    selected: [],
    clases: [],
  }),
  created() {
    this.cargarCiclos();
  },
  methods: {
    async cargarCiclos() {
      try {
        this.loading = true;
        const queryOptions = { registros: -1, pagina: 1 };
        const serverResponse = await cargarCiclosService(
          this.modeloEducativo,
          queryOptions
        );
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.ciclos = serverResponse.ciclos;
          if (this.ciclos.length > 0) this.cicloSel = this.ciclos[0];
          this.cargarClases();
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
    async cargarClases() {
      try {
        this.loading = true;
        const bodyOptions = {
          modelo: this.modeloEducativo,
          ciclo: this.cicloSel._id,
        };
        const serverResponse = await cargarClasesAsignadasService(bodyOptions);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.clases = serverResponse.clasesResult;
        }
      } catch (error) {
        this.loading = false;
        if (!error.handdled) this.$appErrorMessage();
      }
    },
    async aplicarACursos() {
      try {
        this.loading = true;
        let data = {
          idPlantilla: this.idCurso,
          clases: this.selected,
        };
        const serverResponse = await aplicarPlantillaService(data);
        this.loading = false;
        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.$emit("cancelar");
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style>
.disabled-button {
  pointer-events: none;
  opacity: 0.2;
}
</style>
